.headshot {
    width: calc(241px * 1.5);
    height: calc(264px * 1.35);
    vertical-align: middle;
    border-radius:50%;
    box-shadow: rgba(0, 0, 250, 0.4) 5px 5px, rgba(0, 0, 250, 0.3) 10px 10px, rgba(0, 0, 250, 0.2) 15px 15px, rgba(0, 0, 250, 0.1) 20px 20px, rgba(0, 0, 250, 0.05) 25px 25px;
}

.summary-container {
    display: flex;
    align-items: center;
    height: 100vh;
}

.bio-container {
    margin-left: 3vw;
}

.bio {
    font-size: 1.7rem;
}

.bio-salutation {
    font-size: 2rem;
}

.bio-header {
    font-size: 2.5rem;
}

.bio-footer {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 25vw;
    margin: 5vh 15vw;
}

.bio-resume-btn {
    background-color: blue;
    padding: 15px 35px;
    color: #fff;
    border-radius: 40px;
    font-size: 1.5rem;
    font-weight: bold;
    letter-spacing: 2px;
    transition: all 200ms ease-in-out;
    text-decoration: none;
}

.bio-social {
    display: flex;
    width: 5vw;
    justify-content: space-between;
    gap: 2rem;
}

.bio-social-i {
    font-size: 3rem;
    background-color: white;
    padding: 0.3rem;
    color: blue;
    box-shadow: rgb(50 50 93 / 25%) 0px 13px 27px -5px, rgb(0 0 0 / 30%) 0px 8px 16px -8px;
    transition: all 200ms ease-in-out;
}

.btn:hover {
    transform: scale(1.1);
}
