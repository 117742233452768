.about-container {
    display: flex;
    flex-direction: column;
    font-size: 1.3rem;
    box-shadow: rgba(9, 30, 255, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
    padding: 1rem;
}

.about-header {
    font-size: 3rem;
}

.about-body {
    font-size: 2.1ch;
}

.about-body span {
    color: blue;
    font-weight: bold;
}