@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300&display=swap');

body {
    font-family: 'Nunito', sans-serif;
}

.body {
    margin: 3vh 5vw 3vh 13vw;
    display: flex;
    flex-direction: column;
}

html {
    scroll-behavior: smooth;
}