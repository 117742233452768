.heading-container {
    margin-top: 10rem;
    display: flex;
    flex-direction: column;
}

.describer {
    text-transform: uppercase;
}

.describer::before {
    display: inline-block;
    content: "";
    position: relative;
    width: 50px;
    height: 1px;
    background: #333;
    margin-right: 1rem;
}

.heading {
    text-transform: uppercase;
    font-size: 1.7rem;
}